/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you live in the Empire State or any of the New York City boroughs, you’ll appreciate the value of good hearing. From the music of Broadway shows to the power of the roar that greets visitors at Niagara Falls, New York is a state where the sense of hearing is more important than most. So, it makes sense to protect your hearing and take action to redress any problems as they arise."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-miss-out-on-the-sounds-of-new-york-state",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-miss-out-on-the-sounds-of-new-york-state",
    "aria-label": "why miss out on the sounds of new york state permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why miss out on the sounds of New York State?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Imagine what you’ll miss as your hearing deteriorates in New York. As we mentioned above, there’s a lot to hear. Whether you’re attending a hit show like ‘Hamilton’, enjoying a concert at Radio City, watching a live taping of a TV show, or attending a gig in Brooklyn, the Big Apple is a city of music like few others. And if you’re a music fan, you’ll probably appreciate how loud sounds can damage your sense of hearing. But it doesn’t have to be a massive issue, at least with the right hearing aid technology. And the sound of New York doesn’t stop when you leave the theatre. Imagine wandering through Manhattan without the buzz of the streets, the energy of the conversations and even the sounds of subway cars or cabs as they pass by. Imagine heading to Yankee Stadium and not being able to hear the announcer or the satisfying clunk of balls smashing against bats as they soar over the outfield. Or think about heading to the MetLife Stadium to catch a Giants game, only to be met by a dull fuzzy noise instead of the crowd’s roar. Then there’s working life – where hearing every word in meetings is vital if you want to stay on top of your job. And New Yorkers also spend plenty of time in their cars, but what would that be like without the company of radio stations or music from your own collection? Hearing really matters wherever you are, but even more so in New York."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Whether you’re at work or home, hearing loss can be a huge issue for Texans. The state is the epicenter of the country’s oil and gas sector, which means that thousands of Texans are exposed to high noise levels at on-shore drilling stations and off-shore rigs, refineries, in the transportation sector and construction sites."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Live music is a big part of Texas’ entertainment scene, especially in cities like Austin. In the state, people tune into TV and radio in huge numbers as a big part of their entertainment. Around 600,000 Texans hunt regularly, which puts them at risk of regular gunshot discharges."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This is a state where individuals are exposed to high noise levels daily, but need to protect their hearing ability to enjoy life to the fullest. With the right hearing loss treatment and technology, Texans can prevent hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-loss-is-a-big-deal-for-the-people-of-new-york",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-loss-is-a-big-deal-for-the-people-of-new-york",
    "aria-label": "hearing loss is a big deal for the people of new york permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing loss is a big deal for the people of New York"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It probably won’t surprise you, but New Yorkers are exposed to a lot of noise for almost all their adult lives. Statistics show that 40 percent of New York residents aged between 18 and 44 visit loud music venues monthly. Added up throughout your life, that’s the kind of exposure that leads gradually to significant hearing loss. Nationally, around 14 percent of adults suffer from hearing loss at any one time, which adds up to around 3 million people across the State of New York. And 20 percent of Americans report suffering from tinnitus (ringing in the ears) every year. Extrapolated to the population of New York State, that’s another 4 million cases – and in almost all of them the symptoms can be alleviated with the right medical intervention."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearcom-is-helping-new-yorkers-improve-their-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearcom-is-helping-new-yorkers-improve-their-hearing",
    "aria-label": "how hearcom is helping new yorkers improve their hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How hear.com is helping New Yorkers improve their hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The great thing for people with hearing loss is that modern technology is improving all the time, and help is at hand to improve your auditory abilities. At hear.com, we’ve created a state-wide network featuring some of the top audiologists and hearing specialists. Our experts are ready to speak with you over the phone to learn about your situation and recommend the best course of action. Wherever you are in the state, one of local Partner Providers can check your hearing and offer the best solution for you. From locations in upstate New York, like Buffalo or Ithaca, to the state capital, Albany and the Hudson Valley to the city of New York, we’ve gathered together experts who are available to turn your hearing around."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "see-an-expert-in-your-area",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#see-an-expert-in-your-area",
    "aria-label": "see an expert in your area permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "See an Expert in Your Area"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you like the sound of getting professional hearing loss services in New York, all you need to do is complete our online questionnaire. After entering a few pieces of information, we’ll arrange a time and location for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "That way, you can find a hearing loss solution in no time!"), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
